html {

}

body {

 background-color:black !important;

}

.testbg{
  height: 1500px;
  width: 3033px;
    position: absolute;
    left: -500px;
    top: -500px;
    background-image: url('./assets/img/factory-5959.jpg');

    opacity: 0.5;
    z-index:-1
}

.main{
 
}

.hidden {
  display:none !important;
}

.card-hover {
  z-index: 999;
  position: absolute !important;
  width: 90%;
}

.card-hover-calform {
  top:20px;
}

.container {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.query {
  padding-top: 30px;
  margin:auto;
  width: 60%;
  display: flex;
}

.query * {
  margin: 5px;
}

#barcode_data {
  overflow-x: auto;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

#barcode_data table {
  padding-top: 12px;
  padding-bottom: 12px;
  display: block;
  border-collapse: collapse;
}

#barcode_data table tr {
  display: block;
  float: left;
}

#barcode_data table td, #barcode_data table th {
  padding: 8px;
  display: block;
}

#barcode_data table td {
  background-color: #f2f2f2;
}

#barcode_data table th {
  background-color: rgb(54, 50, 50);
  color: white;
}

.chart {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 900px;
  height: 500px;
}

button {
  max-height: 40px;
}

select {
  width: 150px;
}

@media only screen and (max-width: 1200px) {
  #barcode_data table {
      width: 100%;
  }
}

.dblock {
  display:block;
}

.left{
  text-align: left;
}

.w-100 {
  width:100%
}

.iblock{
  display:inline-block;
}

.pright-10{
  padding-right:10px;
}

.app-header .navbar-brand{
  width: 70px !important;
}

.center-data {
  text-align:center;
}

.bg-primary .fa-cog{
  opacity:0.5;
}

