// Here you can add other styles
.nav-pills .nav-link.active {
    border-bottom:2px solid #20a8d8;
    background:none;
    color: #20a8d8;
    border-radius:0;
}

.nav-pills a {
    color: #5C6873;
}

.nav-link {
    padding: 1rem 1rem;
}


.modal-dialog {
    max-width: 900px !important;
}

.rdt_TableHead .rdt_TableHeadRow{
    border-radius:5px 5px 0 0;
    border:1px solid #C8CED3 !important;
    border-bottom:none !important;
    background: #F5F7F9 !important;
}

.rdt_TableCol_Sortable span{
    color: #D3DCE5
}

.rdt_TableBody{
    border:1px solid #C8CED3 !important;
}

.bordered-table{
    border:1px solid #C8CED3 !important;
    border-radius:5px;
}

.rdt_TableHeader{
    display:none !important;
}

.modal-header{
    border:none !important;
}